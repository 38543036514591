import { useState } from "react";
import ReactPlayer from "react-player";

export default function GolfVideoComparison() {
  const [userVideo, setUserVideo] = useState(null);
  const [selectedPro, setSelectedPro] = useState("");
  const [selectedClub, setSelectedClub] = useState("");
  
  const proVideos = {
    "Scottie Scheffler": {
      "9i": "https://example.com/scottie_9i.mp4",
      "7i": "https://example.com/scottie_7i.mp4",
    },
    "Tiger Woods": {
      "9i": "https://example.com/tiger_9i.mp4",
      "7i": "https://example.com/tiger_7i.mp4",
    },
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setUserVideo(url);
    }
  };

  return (
    <div className="flex h-screen p-4 gap-4 bg-gray-100">
      {/* Left Side - User Video Upload */}
      <div className="w-1/2 flex flex-col items-center border p-4 bg-white shadow-md">
        <input type="file" accept="video/*" onChange={handleFileUpload} className="mb-4" />
        {userVideo && <ReactPlayer url={userVideo} controls width="100%" height="400px" />}
      </div>

      {/* Right Side - Pro Video Selection */}
      <div className="w-1/2 flex flex-col items-center border p-4 bg-white shadow-md">
        <select value={selectedPro} onChange={(e) => setSelectedPro(e.target.value)} className="mb-2 p-2 border">
          <option value="">Select Player</option>
          {Object.keys(proVideos).map((player) => (
            <option key={player} value={player}>{player}</option>
          ))}
        </select>

        <select value={selectedClub} onChange={(e) => setSelectedClub(e.target.value)} className="mb-4 p-2 border">
          <option value="">Select Club</option>
          {selectedPro && Object.keys(proVideos[selectedPro]).map((club) => (
            <option key={club} value={club}>{club}</option>
          ))}
        </select>

        {selectedPro && selectedClub && (
          <ReactPlayer url={proVideos[selectedPro][selectedClub]} controls width="100%" height="400px" />
        )}
      </div>
    </div>
  );
}
